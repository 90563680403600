import styles from "./App.module.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./pages/main/Main.jsx";
import Datenschutz from "./pages/datenschutz/Datenschutz.jsx";
import Impressum from "./pages/impressum/Impressum.jsx";

function App() {
  return (
    <div className={styles.App}>
      <Router>
        <Routes>
          <Route path="/" exact element={<Main />}></Route>
          <Route path="/datenschutz" exact element={<Datenschutz />}></Route>
          <Route path="/impressum" exact element={<Impressum />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;

/*<Router>
      <Routes>
        <Route path="/" exact element={<Categroy />}></Route>
        <Route path="/map" exact element={<Map />}></Route>
        <Route path="/questa" exact element={<QuestTakeImage />}></Route>
        <Route path="/questb" exact element={<QuestPreviewImage />}></Route>
        <Route path="/questc" exact element={<QuestUserInput />}></Route>
        <Route path="/input" exact element={<Input />}></Route>
        <Route path="/answers" exact element={<QuestAnswers />}></Route>
      </Routes>
    </Router>*/
