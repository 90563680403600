import React from "react";
import styles from "./Electives.module.css";
import stars from "../../assets/Images/background/milkyway.png";
import planet from "../../assets/Images/planets/Planet-orange-01.png";
import astro from "../../assets/Images/astronaut/astro-floating.png";

function Electives() {
  return (
    <div className={styles.Electives}>
      <div class="row">
        <div class="col-12">
          <h2>SIDE QUESTS</h2>
        </div>
        <div class="col-12 container">
          <div class="col-12">
            <div class="col-4 left">
              <h3>DePerspektive</h3>
              <p>
                Wie beeinflusst Design unser tägliches Leben? Was für eine
                Verantwortung haben Designer? Welche Designrichtungen gibt es
                bereits und wie könnte sich Design verändern, um zu einer
                positiven Zukunft beizutragen? Diese und viele weitere Fragen
                haben sich die Studierenden im Rahmen des Wahlfachs „Design
                Perspektiven“ gestellt. Von Designgeschichte bis hin zu Future
                Design wurden viele Bereiche untersucht und Vorschläge dazu
                gesammelt, in welche Richtung Design gehen könnte. Dieses
                Semester haben sich die Studierenden dem Thema – Digitale
                Souveränität – und der Frage, ob und inwiefern Design Menschen
                zur digitalen Souveränität verhelfen kann, gewidmet.
              </p>
            </div>
            <div class="col-4 center">
              <h3>Zukunftsforschung</h3>
              <p>
                In dem Elective Zukunftsforschung werfen Studierenden einen
                Blick in die Zukunft. Die Studierenden setzen sich als
                Zukunftsforschende mit verschiedenen Zukünften auseinander und
                erfahren, wie sie wissenschaftlich fundiert recherchieren.
                Besprochen wird, wie Zukunft entsteht und wie sie durch
                verschiedene Akteure und Umfelder beeinflusst wird. Die Themen
                können so vielfältig sein, wie die Gedanken zur Zukunft, die die
                Studierenden aus ihrem Studium, ihrem Beruf, ihren Projekten
                oder ihrem persönlichen Bereich mitbringen. Im Mittelpunkt steht
                dabei die Frage, inwiefern das Handeln von Mediengestaltern zum
                Wohl oder zur Last von Menschen, Gesellschaft, Wirtschaft und
                Umwelt wirkt.
              </p>
            </div>
            <div class="col-4 right">
              <h3>3D Rendering</h3>
              <p>
                3D-Animationen werden in Projekten mit den Themen
                Internet-of-Things, Smart /Tangible Media, Digital Interior, VR
                und AR zur Präsentation der Ideen, aber auch zur Skizzierung der
                eigenen Entwürfe im Projektablauf immer wichtiger. Im Kurs wird
                die Software "Cinema 4D" eingesetzt. Er richtet sich an Anfänger
                und Fortgeschrittene und lässt sich bestens an die IMD-Projekte
                anlehnen, aber auch freie eigene Arbeiten können entstehen. Als
                Abgabe wird ein selbst gestaltetes 3D-Modell, ggf. mit
                Animationen, vorausgesetzt, welches dann auf der Werksschau
                vorgestellt wird.
              </p>
            </div>
          </div>
          <div class="col-12">
            <img className="stars" src={stars} alt="planet" />
          </div>
          <div class="col-12">
            <div class="col-4 left">
              <h3>Physical Interaction</h3>
              <p>
                Elektrotechnik, Sensorik, Arduino. Wer mit diesen Begriffen
                nicht viel anfangen kann ist trotzdem an der richtigen Adresse.
                Step by Step wird im Elective "Physical Interaction Lab" das
                Arbeiten mit Mikrocontrollern und deren Programmierung
                nähergebracht. Dabei werden mit etwas Unterstützung eigene
                kleine Projekte entwickelt, die richtig was drauf haben. Es
                folgen Themen wie "automatische Bewässerung von Pflanzen",
                "Lichtshows", "Roboter" und vieles mehr. Nur zusehen? Nein!
                Selber machen ist die Devise!
              </p>
            </div>
            <div class="col-4 center">
              <h3>Uselab</h3>
              <p>
                User-Centered-Design ist zu einem wichtigen Bestandteil im
                Designprozess geworden. Die Uselab-Elective-Reihe zeigt anhand
                praktischer Trainings, wie man Nutzertests zu jedem Zeitpunkt in
                Design-Projekte einbinden kann. Der Methodenkatalog ist dabei
                groß, sodass in jedem Semester andere Arbeitsweisen zum Thema
                Usability-Tests ausprobiert werden können. Dabei lernt man, wie
                man eine Anwendung selbst analysiert, einen Usability-Test
                vorbereitet, durchführt, auswertet und anschließend präsentiert.
              </p>
            </div>
            <div class="col-4 right">
              <h3>Ästhetik</h3>
              <p>
                In dem Kurs "Ästhetik" beschäftigen sich die Studierenden mit
                der ästhetischen Gestaltung von Produkten. Was ist überhaupt
                Ästhetik? Wann ist etwas "schön"? Und wie können wir als
                Gestaltende Ästhetik sinnvoll einsetzen? Diese und viele weitere
                Fragen werden anhand von spannenden Beispielen, Aufgaben und
                Projekten behandelt. In einem eigenen Ästhetik-Guide erarbeiten
                sich die Studierenden eigene Philosophien und Prinzipien durch
                welchen man bei seinem Gestaltungsprozess unterstützt werden
                soll.
              </p>
            </div>
          </div>
          <div class="col-12">
            <div class="col-6">
              <img className="astronaut" src={astro} alt="astronaut" />
            </div>
            <div class="col-6">
              <img className="planet" src={planet} alt="planet" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Electives;
/*<div class="col-12">
          <h2>SIDE QUESTS</h2>
        </div>
        <div class="col-12">
          <div class="container">
            <div class="col-12">
              <div class="col-4 left">
                <h3>DePerspektive</h3>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut laboreet dolore magna
                  aliquyam erat, sed diam voluptua.At vero eos et accusam et
                  justo duo dolores et ea rebum.Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet.
                </p>
              </div>
              <div class="col-4 center">
                <h3>Zukunftsforschung</h3>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut laboreet dolore magna
                  aliquyam erat, sed diam voluptua.At vero eos et accusam et
                  justo duo dolores et ea rebum.Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet.
                </p>
              </div>
              <div class="col-4 right">
                <h3>3D Rendering</h3>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut laboreet dolore magna
                  aliquyam erat, sed diam voluptua.At vero eos et accusam et
                  justo duo dolores et ea rebum.Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet.
                </p>
              </div>
            </div>
            <div class="col-12">
              <img className="stars" src={stars} alt="planet" />
            </div>
            <div class="col-12">
              <div class="col-4 left">
                <h3>Physical Interaction</h3>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut laboreet dolore magna
                  aliquyam erat, sed diam voluptua.At vero eos et accusam et
                  justo duo dolores et ea rebum.Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet.
                </p>
              </div>
              <div class="col-4 center">
                <h3>Uselab</h3>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut laboreet dolore magna
                  aliquyam erat, sed diam voluptua.At vero eos et accusam et
                  justo duo dolores et ea rebum.Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet.
                </p>
              </div>
              <div class="col-4 right">
                <h3>Ästhetik</h3>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut laboreet dolore magna
                  aliquyam erat, sed diam voluptua.At vero eos et accusam et
                  justo duo dolores et ea rebum.Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet.
                </p>
              </div>
            </div>
            <div class="col-12">
              <div class="col-6">
                <img className="astronaut" src={astro} alt="astronaut" />
              </div>
              <div class="col-6">
                <img className="planet" src={planet} alt="planet" />
              </div>
            </div>
          </div>
        </div> */
