import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className={styles.Footer}>
      <div class="col-12">
        <Link to="/">
          <button class="button home">Home</button>
        </Link>
        <Link to="/impressum">
          <button class="button">Impressum</button>
        </Link>
        <Link to="/datenschutz">
          <button class="button">Datenschutz</button>
        </Link>
      </div>
    </div>
  );
}

export default Footer;
