import styles from "./Main.module.css";
import Header from "../../components/header/Header.jsx";
import Footer from "../../components/footer/Footer.jsx";
import IMD from "../../components/IMD/Imd.jsx";
import Trailer from "../../components/Trailer/Trailer.jsx";
import Programm from "../../components/Programm/Programm.jsx";
import Semester from "../../components/semester/Semester.jsx";
import Electives from "../../components/electives/Electives.jsx";
import Avatare from "../../components/avatare/Avatare.jsx";

function Main() {
  return (
    <div className={styles.Main}>
      <Header />
      <IMD />
      <Trailer />
      <Programm />
      <Semester />
      <Electives />
      <Avatare />
      <Footer />
    </div>
  );
}

export default Main;
/*<img
        className="background1"
        src={background1}
        alt="placeholder background"
      />*/
/*<img
        className="background2"
        src={background2}
        alt="placeholder background"
      />*/
/*
      
      
      
      
      
     
      
 */
