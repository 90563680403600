import React from "react";
import styles from "./Trailer.module.css";
import ReactPlayer from "react-player";

function Trailer() {
  return (
    <div className={styles.Trailer}>
      <div class="col-12">
        <h2>LOGBOOK TRAILER</h2>
        <ReactPlayer width="100%" url="https://vimeo.com/576853081" />
      </div>
    </div>
  );
}

export default Trailer;
/* <div class="col-12 video">
        <ReactPlayer url="https://vimeo.com/576853081" />
      </div>*/
