import React from "react";
import styles from "./Avatare.module.css";
import Zweities from "../../assets/studentInfo/zweities.json";
import Vierties from "../../assets/studentInfo/vierties.json";
import Sechsties from "../../assets/studentInfo/sechsties.json";
//import * as zweities from "../../assets/Images/Avatare/zweities";
//import zweities from "../../assets/Images/Avatare/zweities/avatar_2_1.png";

function Avatare() {
  return (
    <div className={styles.Avatare}>
      <div class="row">
        <div class="col-12">
          <h2>CREW</h2>
        </div>
        <div class="col-12">
          {Zweities.map((zweitie) => {
            return (
              <div class="col-2 content">
                <img
                  className="avatar"
                  src={require(`../../assets/Images/Avatare/zweities/${zweitie.image}`)}
                  alt="placeholder avatar"
                />
                <div class="overlay">
                  <p className="resize">
                    {zweitie.name}
                    <br />
                    2. Semester
                    <br />
                    <span class="email"> {zweitie.email}</span>
                  </p>
                </div>
              </div>
            );
          })}
          {Vierties.map((viertie) => {
            return (
              <div class="col-2 content">
                <img
                  className="avatar"
                  src={require(`../../assets/Images/Avatare/vierties/${viertie.image}`)}
                  alt="placeholder avatar"
                />
                <div class="overlay">
                  <p className="resize">
                    {viertie.name}
                    <br />
                    4. Semester
                    <br />
                    <span class="email"> {viertie.email}</span>
                  </p>
                </div>
              </div>
            );
          })}
          {Sechsties.map((sechstie) => {
            return (
              <div class="col-2 content">
                <img
                  className="avatar"
                  src={require(`../../assets/Images/Avatare/sechsties/${sechstie.image}`)}
                  alt="placeholder avatar"
                />
                <div class="overlay">
                  <p className="resize">
                    {sechstie.name}
                    <br />
                    6. Semester
                    <br />
                    <span class="email"> {sechstie.email}</span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Avatare;
/*<img className="planet-left" src={avatar} alt="placeholder avatar" /> */
/*function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));

<img src={images['doggy.png']} /> */

/*{
  todos.map((todo) => (
    <p key={todo.id}>
      {todo.text} - {todo.status}
    </p>
  ));
}*/
