import styles from "./Impressum.module.css";
import Header from "../../components/header/Header.jsx";
import Footer from "../../components/footer/Footer.jsx";

function Impressum() {
  return (
    <div className={styles.Impressum}>
      <h1>Impressum</h1>
      <div class="content">
        <h3>Angaben gemäß §5 TMG</h3>
        <p>
          IMD-F – Interactive Media Design Förderverein e.V. <br></br>
          Max-Planck-Strasse 2<br></br>
          64807 Dieburg <br></br>Vereinsregister: VR 84108 <br></br>
          Registergericht: Amtsgericht Darmstadt <br></br>Vertreten durch:
          Vorsitzende Andrea Krajewski
        </p>

        <h3>Kontakt</h3>
        <p>
          Telefon: +49-6151-16-39450 <br></br>E-Mail: info@imd-f.de
        </p>

        <h3>Postadresse</h3>
        <p>
          IMD-F – Interactive Media Design Förderverein e.V.<br></br>
          c/o<br></br>
          Hochschule Darmstadt<br></br>
          University of Applied Sciences<br></br>
          Mediencampus<br></br>
          Max-Planck-Strasse 2<br></br>
          64807 Dieburg
        </p>

        <h3>Verbraucherstreitbeilegung / Universalschlichtungsstelle</h3>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
          <br></br>Quelle: e-recht24.de
        </p>
      </div>

      <Footer />
    </div>
  );
}

export default Impressum;
