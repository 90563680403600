import React from "react";
import styles from "./Semester.module.css";
import zweitiePlanet from "../../assets/Images/planets/Planet-violet-02.png";
import viertiePlanet from "../../assets/Images/planets/Planet-green-02.png";
import sechstiePlanet from "../../assets/Images/planets/Planet-hellblau-02.png";
import astro from "../../assets/Images/astronaut/astro-drawing-stars.png";

function Semester() {
  return (
    <div className={styles.Semester}>
      <div class="row">
        <div class="col-12">
          <h2>Missions</h2>
        </div>
        <div class="col-12 container">
          <div class="col-12">
            <div class="col-7">
              <div class="content-left">
                <h3>Planet P2</h3>
                <p>
                  "Was wäre, wenn" - mit dieser Fragestellung beschäftigt sich
                  derzeit das zweite Semester. Ziel des Semesterprojekts ist es,
                  eine interaktive Simulation zu entwickeln, um das ausgewählte
                  Thema zu verdeutlichen und den Nutzenden die Möglichkeit zu
                  bieten, das interaktive Experiment modellhaft auszuprobieren.
                  <br></br>
                  <br></br>Die Themenbereiche für das Projekt sind vielfältig.
                  Von Umwelt und Gesundheit, über Mobilität bis hin zur Politik
                  & Wirtschaft ist alles vertreten. Die Aufgabenstellung war
                  dabei sehr frei gestaltet, sodass ein breites Spektrum an
                  Projekten entstehen konnte.
                </p>
              </div>
            </div>
            <div class="col-5">
              <img
                className="zweitie"
                src={zweitiePlanet}
                alt="placeholder planet"
              />
            </div>
          </div>

          <div class="col-12">
            <div class="col-5">
              <img
                className="viertie"
                src={viertiePlanet}
                alt="placeholder planet"
              />
            </div>
            <div class="col-7">
              <div class="content-right">
                <h3>Planet P4</h3>
                <p>
                  "Wir benötigen Dinge, um unsere Welt zu begreifen". <br></br>
                  Das 4. Semester beschäftigt sich mit haptischen Dingen und wie
                  die Vorteile aktiv eingesetzt werden können, um die
                  Interaktion zwischen Handlungspartnern zu verbessern.{" "}
                  <br></br>
                  Doch was sind überhaupt Dinge? Warum brauchen wir Dinge für
                  Interaktionen? Wo liegt der Unterschied zwischen Kommunikation
                  und Interaktion? Wie nutzen wir Dinge? <br></br>
                  <br></br>
                  Mit dabei sind spannende Projekte zu Themen wie "Kommunikation
                  im Straßenverkehr", "Zusammenleben in der WG", "Coding für
                  Kinder" und vieles mehr.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="col-7">
              <div class="content-left">
                <h3>Planet P6</h3>
                <p>
                  "Das Unsichtbare in der Umgebung gestalten" - das ist das
                  derzeitige Thema des 6. Semesters. Hierbei wird sich mit der
                  parallelen analogen und digitalen Welt beschäftigt und diese
                  in unseren Projekten vereint. Mit der analogen Welt sind Dinge
                  gemeint die wir in unserer Umgebung begreifen können, die in
                  Systemen bestehen. Die digitale Welt bildet die Informationen
                  und Programme, die um uns tagtäglich herumschwirren.
                  Zugänglich sind diese durch unsere Handys, Laptops und weitere
                  Interfaces. <br></br>
                  <br></br>Wie kann man die digitale Welt jedoch noch in die
                  analoge einbringen als die bestehenden Geräte? Wie kann die
                  für uns ohne Endgeräte unsichtbare digitale Welt anders in
                  unsere analoge Umgebung eingebunden werden? Und was macht
                  diese sonst unsichtbare Welt eigentlich mit uns?
                </p>
              </div>
            </div>
            <div class="col-5">
              <img
                className="sechstie"
                src={sechstiePlanet}
                alt="placeholder planet"
              />
            </div>
          </div>
        </div>
        <div class="col-7">
          <img className="astronaut" src={astro} alt="astronaut" />
        </div>
      </div>
    </div>
  );
}

export default Semester;
