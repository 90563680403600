import React from "react";
import styles from "./Programm.module.css";

function Programm() {
  return (
    <div className={styles.Programm}>
      <div class="col-1 col-12">
        <h2>TIMETABLE</h2>
      </div>
      <div class="col-12">
        <div class="col-6">
          <div class="dayOne">
            <h3>27. Juni</h3>
            <p className="timetable split">
              <strong>2. Semester</strong>
              <br></br>
              09:30 Uhr &emsp;&emsp;&emsp; <span>Como</span>
              <br></br>
              09:50 Uhr &emsp;&emsp;&emsp; <span>Carfree!</span>
              <br></br>
              10:10 Uhr &emsp;&emsp;&emsp; <span>nocturnal</span>
              <br></br>
              10:30 Uhr &emsp;&emsp;&emsp; <span>Drop</span>
              <br></br>
              <small>
                <i>10:50 Uhr Pause</i>
              </small>
              <br></br>
              11:20 Uhr &emsp;&emsp;&emsp; <span>Clark</span>
              <br></br>
              11:40 Uhr &emsp;&emsp;&emsp; <span>Simulation eines</span>
              <br></br> <span>Atombombeneinschlags</span>
              <br></br>
              12:00 Uhr &emsp;&emsp;&emsp; <span>Exploration Mars</span>
              <br></br>
              <br></br>
              <br></br>
              <strong>6. Semester</strong>
              <br></br>
              13:50 Uhr &emsp;&emsp;&emsp; <span>#localpoet</span>
              <br></br>
              14:15 uhr &emsp;&emsp;&emsp; <span>reQ</span>
              <br></br>
              14:40 Uhr &emsp;&emsp;&emsp; <span>eeco</span>
              <br></br>
              <small>
                <i>15:05 Uhr Pause</i>
              </small>
              <br></br>
              15:35 Uhr &emsp;&emsp;&emsp; <span>Homie</span>
              <br></br>
              16:00 Uhr &emsp;&emsp;&emsp; <span>besides</span>
              <br></br>
              <small>
                <i>16:25 Uhr Ende</i>
              </small>
              <br></br>
            </p>
          </div>
        </div>
        <div class="col-6">
          <div class="dayTwo">
            <h3>28. Juni</h3>
            <p className="timetable split">
              <strong>4. Semester</strong>
              <br></br>
              10:30 Uhr &emsp;&emsp;&emsp; <span>FiveSenses</span>
              <br></br>
              10:55 Uhr &emsp;&emsp;&emsp; <span>PuzZiel</span>
              <br></br>
              11:20 Uhr &emsp;&emsp;&emsp; <span>Hand in Hand</span>
              <br></br>
              <small>
                <i>11:45 Uhr Pause</i>
              </small>
              <br></br>
              12:45 Uhr &emsp;&emsp;&emsp; <span>Logico</span>
              <br></br>
              13:10 Uhr &emsp;&emsp;&emsp; <span>via</span>
              <br></br>
              13:35 Uhr &emsp;&emsp;&emsp; <span>FoodFrame</span>
              <br></br>
              <small>
                <i>14:00 Uhr Gemeinsamer Ausklang mit Picknick</i>
              </small>
              <br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Programm;
