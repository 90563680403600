import React from "react";
import styles from "./Header.module.css";

function Header() {
  // Set the date we're counting down to
  var countDownDate = new Date("Jul 27, 2022 09:30:00").getTime();

  // Update the count down every 1 second
  var x = setInterval(function () {
    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Output the result in an element with id="demo"
    document.getElementById("demo").innerHTML =
      "T- " + days + " day(s) " + hours + " hours " + minutes + " minutes";
    // If the count down is over, write some text
    if (distance < 0) {
      clearInterval(x);
      document.getElementById("demo").innerHTML = "27 - 28 Juli 2022";
    }
  }, 1000);
  return (
    <div className={styles.Header}>
      <h1>WILLKOMMEN IM IMDVERSUM</h1>
      <h3 id="demo"></h3>
    </div>
  );
}

export default Header;
/*<img
        className="background1"
        src={background1}
        alt="placeholder background"
      />*/
