import React from "react";
import styles from "./Imd.module.css";
import planet from "../../assets/Images/planets/Planet-purple-01.png";

function IMD() {
  return (
    <div className={styles.Imd}>
      <div class="row">
        <div class="col-12">
          <div class="col-5">
            <img className="planet" src={planet} alt="placeholder planet" />
          </div>
          <div class="col-7">
            <div class="content">
              <h3>Interactive Media Design</h3>
              <p>
                In Interactive Media Design werden drei Disziplinen, Management,
                Design und Technik, zusammengeführt und aus einer neuen
                Perspektive betrachtet. Die Inhalte werden praxisorientiert
                vermittelt. In jedem Semester werden in kleinen Teams Projekte
                durchgeführt. Dabei gibt es für die Studierenden immer neue
                Semesterthemen aus denen sie eigenständig Fragestellungen bilden
                und sich damit in den Projekten auseinandersetzen. Die
                Studieninhalte werden von einem interdisziplinären Betreuerteam
                direkt in den Projektteams vermittelt. Das Team besteht aus
                Professoren und auch immer wieder aus Dozierenden direkt aus der
                Wirtschaft. Die Projekte haben teilweise einen direkten
                Marktbezug oder werden gemeinsam mit einem Unternehmen
                durchgeführt. Projektpartner sind z.B. Honda, Opel,
                Städel-Museum, ZDF, Siemens oder Telekom.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IMD;
// <img className="planet" src={planet} alt="placeholder planet" />
/*<img
        className="background2"
        src={background2}
        alt="placeholder background"
      /> */
